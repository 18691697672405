/* StepForm.css */
.progressbar {
    counter-reset: step;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 0;
    list-style-type: none;
    width: 50%;
    margin: auto;
  }
  
  .progressbar li {
    counter-increment: step;
    text-align: center;
    position: relative;
    width: 100%;
    font-size: 14px;
  }
  
  .progressbar li:before {
    content: '';
    width: 14px;
    height: 14px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    line-height: 25px;
    border-radius: 50%;
    background-color: #fff;
  }
  
  .progressbar li:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ddd;
    top: 6px;
    left: -50%;
    z-index: -1;
  }
  
  .progressbar li:first-child:after {
    content: none;
  }
  
  .progressbar li.active:before,
  .progressbar li.active + li:after {
    background-color: #27ae60;
    color: white;
    border: none;
  }
  
  .progressbar li.active + li:after {
    background-color: #27ae60;
  }
  .container.sec {
    padding: 40px;
    width: 800px;
    margin: auto;
    box-shadow: 0px 10px 20px darkgrey;
    border-radius: 20px;
    margin: 5rem auto;
}


.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}
input:invalid {
    box-shadow: none;
}
.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}
.asterisk{
    color:red;
}
.step_heading{
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: black;
}
.step_heading_child{
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: black;
}
.field_error{
  color:red;
}
body{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}
.width-half{
  width:50%;
}
.width-full{
  width:100%;
}
.flex{
  display:flex;
}
.carrier_next_submit_btn{
    border-radius: 5px;
    padding: 10px 20px;
    float: right;
}
.carrier_add_remove_btn{
  border-radius: 5px;
  padding: 6px 12px;
  float: right;
  margin-left: 15px;
}
.languages_checkbox{
  display: flex;
    align-items: center;
    justify-content: space-between;
}
.languages_checkbox .checkbox_field{
    display: flex;
    align-items: center;
    gap:10px;
  }
  .languages_checkbox .checkbox_field input{
  margin-bottom: 5px;
}
.key_skills{
  display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2rem;
}
.key_skills input{
margin-bottom: 0px;
}
.key_skills span{
 width:30%;
  }
.justify-between{
  justify-content: space-between;
}
.gap-10px{
  gap:10px;
}
.key_skills input{
   width:auto;
}




