@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

/* 
 

/* navbar style start  */
.navsection{
  background-color: #fff;
}
.main-nav {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.logo .mainlogo{
  width:200px;
}
.menu-link ul {
  height: 10rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.social-media ul {
  height: 10rem;
  
  
  align-items: center;
  justify-content: flex-end;
}

.social-media ul li {
  text-align: right;
}


/* ----------- Grid part ends ----------------- */

.logo h2 {
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#eee, rgb(69, 57, 248));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo h2 span {
  font-size: 3.5rem;
}

.menu-link ul li {
  font-size: 1.8rem;
}

.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
}
.menu-link ul li:hover > a {
  transform-origin: left;
  color: rgba(0, 0, 3, 1);
  
  transition: 0.5s;
}

.social-media ul li {
  font-size: 1.8rem;
}

.hamburger-menu {
  display: none;
}

.facebook {
  color: #3b5998;
}

.instagram {
  color: #c32aa3;
}

.youtube {
  color: #ff0000;
}

/* hero section   */

.hero-section {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-section p {
  font-size: 3rem;
  text-transform: capitalize;
}

.hero-section h1 {
  font-size: 5rem;
  text-transform: uppercase;
  text-align: center;
}

/* responsive css style  */
@media (max-width: 1080px) {
  .main-nav {
    height: 8rem;
    grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
  }

  .logo,
  .menu-link ul,
  .social-media ul {
    height: 8rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  .main-nav {
    height: 7rem;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
  }

  .menu-link {
    display: none;
  }

  .logo,
  .social-media ul {
    height: 7rem;
  }

  .mobile-menu-link {
    position: absolute;
    z-index: 99;
    top: 100px;
    left: 0px;
    width: 102%;
  }

  .mobile-menu-link {
    background-color: white;
    height: auto;
    padding-top:12px;
   display: block;
    align-items: center;
    padding-left: 3rem;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .social-media {
   
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }

  .social-media .social-media-desktop {
    height: 0;
    display: none;
  }

  .social-media {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 798px) {
  .main-nav {
    height: 6rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }

  .logo,
  .social-media ul {
    height: 6rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 520px) {
  .main-nav {
    height: 6rem;
    
  }
  .mobile-menu-link ul{
    height: auto;
    display: block;
    width: 88%;
  }
  .mobile-menu-link ul li{
    display: block;
    text-align: center;
  }
  .logo,
  .social-media ul {
    height: 6rem;
  }

  .logo h2 {
    font-size: 2rem;
  }

  .social-media {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }

  /* hero section  */

  .hero-section h1 {
    font-size: 3.8rem;
  }
}
