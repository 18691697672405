.tablist-inner {
    display: inline-block;
}
.pv-tab-button {
    display: flex;
    justify-content: center;
    list-style: none;
    /* background: rgb(228 188 13 / 13%); */
    border-radius: 6px;
    margin: 35px auto 30px;
    flex-wrap: wrap;
    padding: 0 36px;
    outline: none;
}
.pv-tab-button li {
    padding: 0 20px;
    color: #101010;
    font-size: 16px;
    cursor: pointer;
    outline: none;
}

.pv-tab-button li a.active span {
    color: #4cc2c0;
}
.pv-tab-button li a.active:after {
    width: 100%;
}

.pv-tab-button li a:after {
    position: absolute;
    left: 0;
    background: #4cc2c0;
    bottom: 0;
    height: 3px;
    content: "";
}

.pv-tab-button li a {
    cursor: pointer;
    display: block;
    padding: 20px 0;
    position: relative;
}

/* .thumbnails img {
    border-radius: 5px;
    box-shadow: 0 25px 65px rgb(0 0 0 / 10%);
} */
/* .thumbnails a img{
    transition: all .3s cubic-bezier(.645,.045,.355,1);
} */
/* .item-portfolio-static:hover .thumbnails a img {
    -webkit-transform: translate3d(0,-10px,0);
    transform: translate3d(0,-10px,0);
    box-shadow: 0 50px 80px -10px rgb(0 0 0 / 17%);
} */
.item-portfolio-static .inner {
    padding-top: 20px;
    color: #1d1d24;
}
.item-portfolio-static .inner p {
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 16px;
    color: #e6c02c;
    font-weight: 700;
}

.item-portfolio-static{
    margin-top: 5%;
}
.item-portfolio-static .inner h4{
    margin-top: 0px;
}
.item-portfolio-static .inner h4 a {
    color: #1f1f25;
    transition: .3s;
}
.item-portfolio-static .inner h4 a:hover {
    color: #f9004d;
}
.case-item__thumb img{
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
}