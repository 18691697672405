html{
    scroll-behavior: smooth;
}

body{
    overflow-x: hidden;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
.our_client_item{
    width:50%;
}
.mt-3{
    margin-top:30px;
}
.mb-3{
    margin-bottom:30px;
}
.ml-3{
    margin-left:30px;
}
.mr-3{
    margin-right:30px;
}

.mb-2{
    margin-bottom: 20px;
}
.mb-4{
    margin-bottom: 40px;
}

.mt-6{
    margin-top:55px;
}
.mb-6{
    margin-bottom:55px;
}
.ml-6{
    margin-left:55px;
}
.mr-6{
    margin-right:55px;
}

.mb-0{
	margin-bottom:0px!important;
}




.pt-3{
    padding-top:30px;
}
.pb-3{
    padding-bottom:30px;
}
.pl-3{
    padding-left:30px;
}
.pr-3{
    padding-right:30px;
}

.py-1{
    padding:10px 0px;
}
.py-2{
    padding:20px 0px;
}
.py-3{
    padding:30px 0px;
}
.py-4{
    padding:40px 0px;
}


.my-1{
    margin:10px 0px;
}
.my-2{
    margin:20px 0px;
}
.my-3{
    margin:30px 0px;
}
.my-4{
    margin:40px 0px;
}
.mt-5{
    margin-top:50px;
}


.pt-6{
    padding-top:55px;
}
.pb-6{
    padding-bottom:55px;
}
.pl-6{
    padding-left:55px;
}
.pr-6{
    padding-right:55px;
}
.pb-0{
	padding-bottom:0px!important;
}

.info-box--standard{
	text-align: center;
    padding: 20px 10px;
    background: #fff;
    border-radius: 8px;
	box-shadow: 4px 4px 10px;
}
.info-box-content{
	text-align:center;
}


.page_wrapper{
    margin-top:100px;
    margin-bottom: 50px;
}
.letborder{
    border-left:7px solid #e81e79;
}

.d-flex{
    display: flex;
}
.about_magento > div{
    width:80px;
}
.justify-ceontent-center{
    justify-content: center;
}
.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.anylist_content{
    padding:10px;
    border:1px solid #d0d0d0;
    border-radius: 4px;
}

.anylist_content ol li a{
    color:#79ad36;
    font-size: 17px;
    text-decoration: none;
    transition: 04s;
}
.anylist_content ol li{
    padding:5px 0px;
}
.anylist_content ol li a:hover{
    color:#d0d0d0;
}
.contect_link{
    padding:50px;
    background-repeat: no-repeat;
    background-size: cover;
    color:#fff;
}
.contect_link h4{
    color:#fff;
    font-size:24px;
    margin-bottom:20px;
}
.btn_ {
    font-family: "Open Sans","Helvetica Neue",Arial,Helvetica,Sans-Serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    padding: 12px;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 14px;
    line-height: 18px;
    height: auto;
}
.contect_btn{
    background-color: #404041;
    border-color: #272727;
}
.about_magento_img_wrap>img{
    width:225px;
}



.webdevloper .banner{
    position:relative;
    height:1000px;
    
}
.position-absolute{
    position: absolute;
}
.banner-img1{
    right:-330px;
    z-index: -2;
}
.banner-img2{
    right:-250px;
}




@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
.inpt-styl{
    width: 100%;
    margin-bottom: 22px;
    line-height: 25px;
   
    margin-top: 10px;
    border:none;
    background: #fff;
    border: 1px solid #3b69ff;
   font-size: 15px;

    padding: 5px 14px;
    border-radius: 3px;
    
}
 .pbt-p{
     margin-bottom:30px;
 }
.pt1{
    padding-top: 155px;
}
.inpt-styl1{
    width: 100%;
    margin-bottom: 0px;
    line-height: 23px;
   
    margin-top: 10px;
    border:none;
    background: #fff;
       border: 1px solid #3b69ff;
   
    font-size: 13px;
    padding: 5px 14px;
    border-radius: 3px;
    
}
.pbp{
         padding-bottom: 430px;
     }
select option{
    margin-left:-2px;
}
.form-bx{
    position: absolute;
    top: 57%;
    width: 44%;
    padding: 26px;
    left: 50px;
    box-shadow: 0 10px 30px rgb(0 0 0 / 7%);
    border-radius: 4px;
    -webkit-transform: translate(0px, -60%);
    transform: translate(0px, -60%);
}

.frm-h{
    color:#3363ff;
    font-weight: 500;
    font-family: 'Poppins';
}
.bt{
    border: none;
}




.bt{
    width: 100%;
    margin-bottom: 3px;
    line-height: 37px;
    background: linear-gradient(70deg, #486de2, #59b5ffb8);
    color: #fff;
    font-size: 20px;
    margin-top: 10px;
    border-radius: 24px;
    box-shadow: 0 10px 30px rgb(0 0 0 / 7%);
    padding: 5px 12px;
    transition: all 0.3s ease;
}
.bt:hover{
    color: #fff;
    background: linear-gradient( 132deg, #2d60ff, #3a94dcb8);
}
.pt{
        padding-top: 70px;
}
.form-head {
    background-color: #3363ff;
    margin-bottom: 40px;
    position: relative;
    text-align: center;
    z-index: 1;
}
.form-head:after {
    background: #3363ff none repeat scroll 0 0;
    border-radius: 100%;
    bottom: -20px;
    content: "";
    height: 80px;
    left: -10%;
    position: absolute;
    width: 120%;
    z-index: -1;
}
.abt-p{
    margin-top: 20px;
    font-family: 'Poppins';
    line-height: 29px;
}
.bread-h{
    position: absolute;
    top: 30%;
    left: 9%;
    color: #000;
    font-size: 40px;
    font-weight: 900;
}
.service-ul{
        list-style: none;
    line-height: 67px;
    padding-left: inherit;
}
.service-ul li{
   
    font-size: 21px;
    font-weight: 600;
}
.service-ul li span{
    padding-left:20px;
}
   #do-h1::before{
     content: '01';
    width: 40px;
    height: 40px;
    background: #fff;
    padding: 6px;
    border-radius: 4px;
    font-family: sans-serif;
    font-weight: 600;
       
       
   }
    #do-h2::before{
     content: '02';
    width: 40px;
    height: 40px;
    background: #fff;
    padding: 6px;
    border-radius: 4px;
    font-family: sans-serif;
    font-weight: 600; 
   }
   #do-h3::before{
     content: '03';
    width: 40px;
    height: 40px;
    background: #fff;
    padding: 6px;
    border-radius: 4px;
    font-family: sans-serif;
    font-weight: 600; 
   }
   #do-h4::before{
     content: '04';
    width: 40px;
    height: 40px;
    background: #fff;
    padding: 6px;
    border-radius: 4px;
    font-family: sans-serif;
    font-weight: 600;
   }
.services {
    padding: 0px;
    margin-bottom: 30px;
    border-radius: 5px;
    position: relative;
}
.services h3 span{
    padding-left: 7px;
    font-size: 31px;
    color: #1690f2;
}
#do-h{
        padding-top: 10px;
}
.icon-flx{
    display: flex; 
    justify-content: flex-end;
}
.line{
      position: absolute;
    top: 47%;
    right: -72%;
}
.line1{
      
    position: absolute;
    top: -75%;
    right: -22%;
    right: -74%;
}

.how-p{
    font-size: 20px; 
    font-family: 'Open Sans', sans-serif; font-weight: 600;
    line-height: 33px;
}   
@keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
  }

  .dia-service-content{
      padding:90px 0px; 
  }
.banner-shape1 {
    left: 50%;
    bottom: 15%;
    z-index: -2;
    animation-name: spin;
    animation-duration: 15000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.dia-service-section .dia-service-img .dia-service-shape1 {
    left: 0;
    top: 10px;
    z-index: -1;
    animation-name: spin;
    animation-duration: 20000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.dia-service-shape2{
    top:0px;
    left:0px;
    z-index: -2;
}

.dia-service-section .dia-service-btn .dia-service-more {
    height: 50px;
    width: 225px;
    margin-top: 10px;
    line-height: 50px;
    border-radius: 40px;
    background-color: #1582f3;
}
.dia-service-section .dia-service-btn .dia-service-more:before{
    left: 0;
    width: 100%;
    content: "";
    z-index: -1;
    height: 100%;
    bottom: -100%;
    position: absolute;
    transition: 0.5s all ease-in-out;
}

.dia-service-section .dia-service-btn .dia-service-more a {
    color: #fff;
    width: 100%;
    display: block;
    font-size: 15px;
    font-weight: 700;
    font-family: "Poppins";
}

.tble {
    background: #fff;
    border-radius: 22px;
    box-shadow: #aba8a8 0px 0px 15px -6px;
    padding: 15px;
    position: relative;
    
}
.main-heading {
    text-align: center;
    color: #28bf79;
    
}

.tble .details li {
    list-style: none;
    margin-bottom: 15px;
    border-bottom: 2px dotted #28bf79;
}
.shop-btn {
    background: #28bf79;
    color: #fff;
    font-weight: 600;
    border-radius: 25px;
    text-align: center;
    padding: 12px 96px;
    width: 90%;
}
.shop-btn2 {
    background: #282350;
    color: #fff;
    font-weight: 600;
    border-radius: 25px;
    text-align: center;
    padding: 12px 96px;
    width: 90%;
}

.shop-btn3 {
    background: #ff1d32;
    color: #fff;
    font-weight: 600;
    border-radius: 25px;
    text-align: center;
    padding: 12px 96px;
    width: 90%;
}
.f38{
    font-size: 38px!important;
}
i.fas{
    font-family:fontawesome;
    color: #585476;
    margin-right:15px;
}
@keyframes fadeFromLeft{
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes fadeFromLeft{
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}


.aminme1{
    visibility: visible;
    animation-duration: 1500ms;
    animation-delay: 0ms;
    animation-name: fadeFromLeft;
}
.aminme1{
    visibility: visible;
    animation-duration: 1500ms;
    animation-delay: 300ms;
    animation-name: fadeFromRight;
}

.w-25{
    width:100%;
}
.w-75{
    width:100%;
}
.info-box-content h5{
    font-size:22px;
}
.list-1{
    width:48%!important;
}
.list-2{
    width:52%!important;
}

@media (min-width: 600px) {
    .w-25{
        width:25%;
    }
    .w-75{
        width:75%;
    }
}

.recent-post-slider .swiper-wrapper{
    align-items: flex-start!important;
}
.post .post__content .post__title{
    height:62px;
}

.post .post__content .post__text {
    margin-bottom: 0;
}

.expert_area .servises-text {
    color: #c1c1c1!important;
}
.p-1{
    padding:10px;
}
.p-2{
    padding:20px;
}
.p-3{
    padding:30px;
}
.p-4{
    padding:40px;
}
.p-5{
    padding:50px;
}
.p-6{
    padding:60px;
}
.what_do p,
.what_notdo p{
    text-align: justify;
}

.top-bar-dark a {
    color: #fff;
}
.follow_us span{
    color:#fff;
}
.servises-item .servises-item__content .servises-text {
    margin-bottom: 30px;
    color: #fff;
    text-align: justify;
}
.testimonial-item.testimonial-arrow .testimonial-text {
    font-size: 16px;
}
.list--secondary a:hover, .list--secondary li:hover {
    color: #b0b0b0;
}
.py-10{
    padding:100px 0px;
}
.align-items-center{
    align-items: center;
}
.pt-8{
    padding-top:80px;
}
.flex-wrap{
    flex-wrap: wrap;
}

.services-content .servises-item{
    min-height: 725px!important;
    padding: 20px 45px!important;
}
.services-content .servises-item  a.read-more{
    position: absolute;
    bottom: 30px;
}
.about-container .icon-box {
    background: #fff;
    background-size: cover;
    padding: 0 0 30px 0;
   
} 
.about-container .icon-box .icon {
    float: left;
    background: #fff;
    width: 64px;
    height: 64px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    border-radius: 50%;
    border: 2px solid #007bff;
    transition: all 0.3s ease-in-out;
}
.about-container .icon-box .icon i {
    color: #007bff;
    font-size: 24px;
}
.about-container .icon-box:hover .icon i {
    color: #fff;
}
.about-container .icon-box:hover .icon {
    background: #007bff;
}
.about-container .icon-box .description {
    margin-left: 80px;
}
.about-container .icon-box .title {
    margin-left: 80px;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 18px;
}
.linkedin{
    width:23px!important;
}
.swiper-wrapper{
    align-items: normal;
}

@media (max-width: 600px) {
    .form-bx {
        width: 100%; 
        left:0px;
    }
    .banner-img2 {
        right: 0px;
        display: none;
    }
    .banner-img1 {
        right: 0px;
        
    }
    .line1,
    .line{
        display: none;
    }
    .services {
        text-align: center;
    }
    .icon-flx {
        justify-content: center;
    }

    .dia-service-section .dia-service-img .dia-service-shape1 {
        -webkit-animation-name: spin1;
    }
    .pt-12{
        /* padding-top:120px!important; */
        margin-top: 120px!important;
    }
    .cd-headline{
        text-align: center;
    }
    .dia-service-content {
        padding: 0px 0px;
    }
    .dia-service-text{
        margin-top: 50px;
    }
    .tble{
        margin-bottom: 15px;
    }
    .info-box--standard .info-box-image{
        margin-right: 0px;
        margin-bottom: 0px;
    }
    .heading .heading-title {
        margin-bottom: 10px;
        margin-top: 16px;
    }
    .swiper-slide{
        text-align: center;
    }
    .testimonial-slider {
        padding: 45px 0 80px 0;
    }
}
@media (max-width: 360px){
    .offers .btn {
        margin-left: 20px;
    }
}

@media (max-width: 1000px) {
    .form-bx {
        width: 60%;
        left: 22%;
        
        top: 67%;
    }
    .banner-img2 {
        right: 0px;
        display: none;
    }
    .banner-img1 {
        right: 0px;
        
    }
    .line1,
    .line{
        display: none;
    }
    .services {
        text-align: center;
    }
    .icon-flx {
        justify-content: center;
    }

   
    .cd-headline{
        text-align: center;
    }
    .dia-service-content {
        padding: 0px 0px;
    }
    .dia-service-text{
        margin-top: 50px;
    }
    .tble{
        margin-bottom: 15px;
    }
    .ecommerce .servises-item {
        margin-bottom: 10px;
    }
}


/* navbar style start hare */

